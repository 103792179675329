<template>
<section :style="{ backgroundImage : `url(${mapper.imgUrl}?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)` }"  class="flex justify-center items-center w-full h-full bg-gray-700 bg-blend-multiply">
    <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
        <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-white md:text-4xl lg:text-5xl">{{mapper.title}}</h1>
        <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">{{mapper.intro}}</p>        
    </div>
</section> 
</template>
<script setup>
import { defineProps } from 'vue';
const props = defineProps({
    title : String,    
});
let mapper = computed(() => {
    switch(props.title) {
        case '/':
            return {title: '㈜케이씨파워', imgUrl: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg', intro: '케이씨파워에 방문해주셔서 감사합니다'}
        case '/about/overview':
            return {title: '회사개요', imgUrl: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg', intro: ''}
        case '/about/ceo-message':
            return {title: 'CEO인사말', imgUrl: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg', intro: ''}
        case '/about/way-come':
            return {title: '오시는길', imgUrl: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg', intro: ''}
        case '/product':
            return {title: '제품소개', imgUrl: 'https://img.freepik.com/free-photo/close-up-businessman-with-digital-tablet_1098-549.jpg?w=1200&t=st=1710477523~exp=1710478123~hmac=9ad0989fe7a15cf10afdbe1cd33a394ba4e86c861b389a925a850df210979184', intro: ''}
        case '/pr/press':
            return {title: '보도자료', imgUrl: 'https://plus.unsplash.com/premium_photo-1664297878197-0f50d094db72?w=1200&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG5ld3N8ZW58MHx8MHx8fDA%3D', intro: ''}
        case '/pr/catalog':
            return {title: '카탈로그', imgUrl: 'https://plus.unsplash.com/premium_photo-1664297878197-0f50d094db72?w=1200&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG5ld3N8ZW58MHx8MHx8fDA%3D', intro: ''}
        case '/cs/announcement':
            return {title: '공지사항', imgUrl: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg', intro: ''}
        case '/cs/contact':
            return {title: '상담문의', imgUrl: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg', intro: ''}
        case '/hr/job-intro':
            return {title: '직무소개', imgUrl: 'https://plus.unsplash.com/premium_photo-1661306465544-cc55151ab336?w=1500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW4lMjByZXNvdXJjZXN8ZW58MHx8MHx8fDA%3D', intro: ''}
        case '/hr/process':
            return {title: '채용프로세스', imgUrl: 'https://plus.unsplash.com/premium_photo-1661306465544-cc55151ab336?w=1500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW4lMjByZXNvdXJjZXN8ZW58MHx8MHx8fDA%3D', intro: ''}
        case '/hr/welfare':
            return {title: '복리후생', imgUrl: 'https://plus.unsplash.com/premium_photo-1661306465544-cc55151ab336?w=1500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW4lMjByZXNvdXJjZXN8ZW58MHx8MHx8fDA%3D', intro: ''}
        default:
            return {title: '오시는길', imgUrl: 'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg', intro: ''}
    }
})
</script>
