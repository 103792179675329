<template>
  <div class="flex flex-col min-h-screen">
    <Menubar1 />
    <header>
      <slot name="header">
        <Header :title="useRoute().path"/>
      </slot>
    </header>
    <main class="flex flex-col flex-grow">
      <slot></slot>
    </main>
    <footer>
      <slot name="footer"> <Footer /> </slot>
    </footer>
  </div>
</template>
